import { render, staticRenderFns } from "./edit-category-product.vue?vue&type=template&id=3c0947ac&scoped=true"
import script from "./edit-category-product.vue?vue&type=script&lang=js"
export * from "./edit-category-product.vue?vue&type=script&lang=js"
import style0 from "./edit-category-product.vue?vue&type=style&index=0&id=3c0947ac&prod&scoped=true&lang=css"
import style1 from "@riophae/vue-treeselect/dist/vue-treeselect.min.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=2&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c0947ac",
  null
  
)

export default component.exports